@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Fun:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@400&display=swap');


h1, h2, h3, h4, h5, h6 {
  font-family: 'Reem Kufi Fun', sans-serif;
}

html {
    scroll-behavior: smooth;
  }

  /* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #0c203a; /* Slightly lighter than ocean-blue-900 */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #1a3a5e; /* Slightly darker than ocean-blue-900 */
    border-radius: 6px;
    border: 3px solid #0c203a;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #234b7a; /* Even darker on hover */
  }
  
  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #1a3a5e #0c203a;
  }

  .glow-text {

    font-weight: bold;
    margin-bottom: 1rem;
    animation: glow 1.5s infinite alternate;
    tracking-wider: 0.1em;
    color: white; /* Adjust as needed */
    text-shadow: 
        0 0 5px rgb(29, 29, 69),
        0 0 10px #030f3e,
        0 0 20px #030f3e,
        0 0 40px #0ff,
        0 0 80px #0ff,
        0 0 90px #0ff,
        0 0 100px #0ff,
        0 0 150px #0ff;
}
@keyframes glow {
    from {
        text-shadow: 
            0 0 40px #0ff,
            0 0 80px #0ff,
            0 0 90px #0ff,
            0 0 100px #0ff,
            0 0 150px #0ff;
    }
    to {
        text-shadow: 
          
            0 0 100px #0ff,
            0 0 150px #0ff,
            0 0 200px #0ff,
            0 0 250px #0ff;
    }
}